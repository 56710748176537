<div>
  <div id="carouselExampleSlidesOnly" class="carousel slide padding-none" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="{{ imgTitre }}" class="d-block w-100" alt="Restaurants et loisirs à 50%">
      </div>

    </div>
  </div>
</div>

<!-- les 3 cartes --> <!-- pourCacherCartes à true = cartes visiblent  -->

<div *ngIf="pourCacherCartes === true" class="grid_card haut_cartes">
  <div></div>
  <div>
    <div class="flextest">
      <div class="mobile card home_card"><!--Ajouter style backgroud pour couleur de fond de carte-->
        <h5 class="card_title">1 an</h5>
        <img src="../../assets/images/Reduit/carte2_Mixed_site_reduit.png" class="card-img-top" alt="Vos restaurants & loisirs à 50%">
        <div class="card-body cardre_text_home">
          <h5 class="home_card_text">Restos & loisirs</h5>
          <p class="home_card_text1">pour 159.-</p>
          <!--<p class="home_card_text1" >Actions de Noël</p> Utilisé Noël 2023-->
          <p class="home_btn">
          <a href="https://ebook-card.com/categorie-shop/mixte/" target="_blank" class="btn btn-primary">Découvrir</a>
          <!--<a href="https://www.ebook-card.com/actions-de-noel-2023/" target="_blank" class="btn btn-primary">Découvrir</a> Utilisé Noël 2023 -->
          </p>
        </div>
      </div>
      <div class="desktop card home_card"><!--Ajouter style backgroud pour couleur de fond de carte-->
        <h5 class="card_title">Gastronomie & loisirs à 50%</h5>
        <img src="../../assets/images/Reduit/carte2_Mixed_site_reduit.png" class="card-img-top" alt="Vos restaurants & loisirs à 50%">
        <div class="card-body cardre_text_home">
          <h5 class="home_card_text">Gastronomie & loisirs 1 an</h5>
          <p class="home_card_text1" >pour 159.-</p>
          <!--<p class="home_card_text1" >Nos actions de Noël</p> Utilisé Noël 2023-->
          <p class="home_btn">
          <a href="https://ebook-card.com/categorie-shop/mixte/" target="_blank" class="btn btn-primary">Découvrir</a>
           <!-- <a href="https://www.ebook-card.com/actions-de-noel-2023/" target="_blank" class="btn btn-primary">Découvrir</a> Utilisé Noël 2023-->
          </p>
        </div>
      </div>
      <div class="mobile card home_card">
        <h5 class="card_title">1 an</h5>
        <img src="../../assets/images/Reduit/carte2_Hobbies_site_reduit.png" class="card-img-top" alt="Vos loisirs à 50%">
        <div class="card-body cardre_text_home">
          <h5 class="home_card_text">Loisirs</h5>
          <p class="home_card_text1">pour 99.-</p>
          <p class="home_btn">
          <a href="https://ebook-card.com/categorie-shop/loisir/" target="_blank" class="btn btn-primary">Découvrir</a>
          </p>
        </div>
      </div>
      <div class="desktop card home_card">
        <h5 class="card_title">Loisirs à 50%</h5>
        <img src="../../assets/images/Reduit/carte2_Hobbies_site_reduit.png" class="card-img-top" alt="Vos loisirs à 50%">
        <div class="card-body cardre_text_home">
          <h5 class="home_card_text">Carte loisirs 1 an</h5>
          <p class="home_card_text1">pour 99.-</p>
          <p class="home_btn">
           <a href="https://ebook-card.com/categorie-shop/loisir/" target="_blank" class="btn btn-primary">Découvrir</a>
          </p>
        </div>
      </div>
      <div class="mobile card home_card "> <!-- carte en deux parties, ici mobile-->
       <h5 class="card_title">1 an</h5>
        <img src="../../assets/images/Reduit/carte2_Gastronomic_site_reduit.png" class="card-img-top" alt="Vos restaurants à 50%">
        <div class="card-body cardre_text_home">
          <h5 class="home_card_text">Gastronomie</h5>
          <p class="home_card_text1">pour 99.-</p>
          <p class="home_btn">
          <a href="https://ebook-card.com/categorie-shop/gastronomie/" target="_blank" class="btn btn-primary">Découvrir</a>
          </p>
        </div>
      </div>
      <div class="desktop card home_card ">  <!-- carte en deux parties, ici desktop-->
        <h5 class="card_title">Gastronomie à 50%</h5>
        <img src="../../assets/images/Reduit/carte2_Gastronomic_site_reduit.png" class="card-img-top" alt="Vos restaurants à 50%">
        <div class="card-body cardre_text_home">
          <h5 class="home_card_text">Carte gastronomie</h5>
          <p class="home_card_text1">de 59.- à 99.-</p>
          <p class="home_btn">
          <a href="https://ebook-card.com/categorie-shop/gastronomie/" target="_blank" class="btn btn-primary">Découvrir</a>
          </p>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</div>
<div style="text-align: center;margin-top: 5px; margin-bottom: 5px;"><button (click)="detailOffre()" class="favorite styled"
  type="button">
Découvrir toutes nos cartes
</button>
</div>

<!--Fin des 3 cartes-->

<!--section "Découvrir les restaurants-->
<div class="grid_widget">
  <div></div>
  <div class="flextest flex_colum Widget cadre_home" style="margin-bottom:0px;">
    <div>
      <div class="text_home_resto">
        <p>
          <a routerLink="/restaurants">
            <h3 class="semi-bold-blue text-slogan">Les restaurants</h3>
          </a>
        </p>
        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">-50% pour 2 personnes</span>
        </p>
        
        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">-40% pour 1 ou 3 personnes</span>
        </p>

        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">-30% pour 4 personnes</span>
        </p>

        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">-20% pour 5 ou 6 personnes</span>
        </p>

        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">Réductions sur entrées, plats, desserts</span>
        </p>

        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">Valable en Suisse romande</span>
        </p>
      </div>
    </div>
    <div>
      <div class="flextest_img">
        <p style="margin-bottom:0px;">
          <a routerLink="/restaurants">
            <video class=" img_home_resto" src="../../assets/videos/Reduit/Roast_1.m4v" title="Vos restauants à moitié prix" autoplay="true" loop="" muted="muted" controlslist="nodownload"></video>
          </a>
        </p>
      </div>
    </div>
  </div>
  <div></div>
</div>
<!--section "Découvrir les restaurants-->
<!--Cartes-->
<div class="accordion" id="accordionExample">
  <div class="card map_bottom">
    <div class="card-header" style="padding-bottom:0px;padding-top:0px;" id="headingOne">
      <h2 class="mb-0">
        <button class="favorite styled" style="padding: 10px; line-height: 1.5;" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
           <span style="line-height:5px"> Voir nos partenaires sur la carte</span>
        </button>
      </h2>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body carte_padding">
        <div class="grid_carte">
          <div class="card carte_fond">
            <div class="card-body carte_body">
              <h3 class="titre">Découvrez nos restaurants</h3>
              <agm-map [latitude]="latitude" [longitude]="longitude" style="height: 470px; ">
                <agm-marker *ngFor="let resto of restos, let i = index"
                  (markerClick)="clickedMarkerRestos(resto.id)"
                  [latitude]="resto.latitude" 
                  [longitude]="resto.longitude"
                  [zoom]="zoom"
                  [title] = "resto.name"
                  [iconUrl]="'/assets/images/map_pointer.png'">
                </agm-marker>
              </agm-map>
            </div>
          </div>
          <div class="card carte_fond">
            <div class="card-body carte_body">
              <h3 class="titre">Découvrez nos loisirs</h3>
              <agm-map [latitude]="latitude" [longitude]="longitude" style="height: 470px; ">
                <agm-marker *ngFor="let loisir of loisirs, let i= index"
                (markerClick)="clickedMarkerLoisirs(loisir.id)"
                  [latitude]="loisir.latitude" 
                  [longitude]="loisir.longitude"
                  [title] = "loisir.name"
                  [iconUrl]="'/assets/images/map_pointer_activity.png'">
                </agm-marker>
              </agm-map>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!--Fin cartes-->
<!--section "Découvrir les loisirs-->
<div class="grid_widget">
  <div></div>
  <div class="flex_loisirs_mobile flextest Widget cadre_home" style="margin-bottom:0px;">
    <div>
      <div class="flextest_img">
        <p style="margin-bottom:0px;">
          <a routerLink="/loisirs">
            <video class="img_home_loisirs" src="../../assets/videos/Verticalp.m4v" title="Vos loisirs à moitié prix" autoplay="true" loop="" muted="muted" controlslist="nodownload"></video>
          </a>
        </p>
      </div>
    </div>
    <div>
      <div class="flex_colum text_home_loisirs">
        <p>
          <a routerLink="/loisirs">
            <h3 class="semi-bold-blue text-slogan">Les loisirs </h3>
          </a>
        </p>
        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">1 entrée payante = 1 entrée gratuite</span>
        </p>

        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">Valable de 2 à 6 personnes</span>
        </p>

        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">Valable en Suisse romande</span>
        </p>

        <p class="margin_fa">
          <i class="fa fa-check" aria-hidden="true" style="color:lime"></i>
          <span class="elementor-icon-list-text">Visibilité en direct des disponibilités</span>
        </p>
      </div>
    </div>
  </div>
  <div></div>
</div>
<!--section "Fin découvrir les loisirs-->



<!--section "Partout en Suisse-->
<div class="grid_widget">
  <div></div>
  <div class="flextest flex_colum Widget cadre_home" style="margin-bottom:0px;">
    <div>
      <div class="text_home_partout">
        <p>
          <a routerLink="/restaurants">
            <h3 class="semi-bold-blue text-slogan" style="padding-left:5px;">Partout en Suisse romande</h3>
          </a>
        </p>
        <p class="elementor-icon-list-text">
          <span class="bold-blue-e">e</span>book-card vous ouvre les portes à moitié prix chez plus de 200 partenaires en Suisse romande
        </p>
        <p class="elementor-icon-list-text">
          Dans les cantons de Neuchâtel, Vaud, Valais, Jura, Berne, Genève et Fribourg
        </p>
        <p class="elementor-icon-list-text">
          Nos cartes sont valables une fois chez chacun de nos partenaires
        </p>
      </div>
    </div>
    <div>
      <div id="carouselExampleSlidesOnly" class="carousel carousel_perso img_carou_centre carousel-fade" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item carou_item active">
            <img src="../../assets/images/Reduit/Carou_resto.jpg" class="d-block img_carou carousel_bordeur" alt="Vos restaurants & loisirs à 50%">
          </div>
          
          <div class="carousel-item carou_item">
            <img src="../../assets/images/Reduit/Carou_resto1_reduit.jpg" class="d-block img_carou carousel_bordeur" alt="Vos restaurants & loisirs à 50%">
          </div>
          <div class="carousel-item carou_item">
            <img src="../../assets/images/Reduit/Carou_loisir1.jpg" class="d-block img_carou carousel_bordeur" alt="Vos restaurants & loisirs à 50%">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</div>
<!--section "Partout en Suisse-->

<!-- section Newsletter -->
<div style="padding-bottom:20px" class="grid_widget">
  <div></div> <!--Pour la taille de la fenêtre-->
  <div class="Widget " style="margin-bottom:0px;">
    <div>
        <p style="margin-bottom: 5px;">
          <a>
            <iframe width="540" height="320" src="https://e258d80c.sibforms.com/serve/MUIEAMOvkKmg6iqplDMI8TSEE8_OMjcB-sGta0z7hNuW77hQ2T10qs01oiI4BSF-njxKn0skCBHzbdQo9gm-5D-r8NePw6s0vrxdlnwjWcknUkhpjFpxMhfFVGwpy4-fMtagMfXTArd3XhpfYYU4btGPcQPDb_y4mE_BIH3MCAiF0VKkshG3ITxEJW0JJZSLibAqfPlOQZiA68NC" frameborder="0" scrolling="no" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
            <br>
          </a>
        </p>
    </div>
  </div>
</div>